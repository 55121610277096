import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getOrderById } from '../../../store/reducers/order';

const CustomerDetails = (props) => {
  const { orderData } = props;
  const { id, productId } = useParams();

  return (
    <div className="box_main">
      <div className="header_top ">
        <h3>Customer Details</h3>
      </div>
      <div className="middle_main">
        <div className="row">
          <div className="col-12">
            <table className="table condensed_table">
              <tbody className="order_details">
                <tr>
                  <td width="30%">
                    <p className="customer_title_1">Customer Name:</p>
                  </td>
                  <td width="70%">
                    <p className="customer_title_2">
                      {`${orderData?.user?.first_name} ${orderData?.user?.last_name}`}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="customer_title_1">Phone Number:</p>
                  </td>
                  <td>
                    <p className="customer_title_2">{orderData?.user?.phone}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="customer_title_1">Delivery Details:</p>
                  </td>
                  <td>
                    <p className="customer_title_2">
                      {`${orderData?.address?.address_line1}`}
                      <br />
                      {orderData.address.address_line2
                        ? `${orderData?.address?.address_line2}`
                        : ''}
                      {`${orderData?.address?.city}, ${orderData?.address?.zip_code}, ${orderData?.address?.country}`}
                      <br />
                      {`Type:- ${orderData?.address?.title}`}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetails;
