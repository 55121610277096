import { Form, Formik } from 'formik';
import React from 'react';
import { propTypes } from 'react-bootstrap/esm/Image';
import { SubscriptionSpanOptions } from '../../../common/constants';
import CustomInput from '../../../common/FormComponents/Input';
import TextEditor from '../../../common/TextEditor';
import CustomDropdown from '../../../common/UI/Dropdown';
import SpecificQuestions from '../SpecificQuestions';
import { Spinner } from 'react-bootstrap';

const SubscriptionDetails = (props) => {
  const {
    productData,
    handlePrevious,
    handleSubmit,
    isSubmitDisabled,
    handleChange,
    handleAddSubQuestion,
    handleAddMainQuestion,
    handleChangeForMainQuestion,
    handleChangeForSubQuestion,
    handleDeleteMainQuestion,
    handleDeleteSubQuestion,
    handleSelectQuestionModalToggle,
    onDragEndQuestion,
    setSelectedQuestionsIndex,
    toggleShowOptionsModal,
  } = props;
  return (
    <>
      <div className="row">
        <div className="col-12 mt-2 mb-4">
          <hr />
        </div>
        <div className="col-12">
          <h4 className="middle_title">Subscription Details</h4>
        </div>

        <div className="row">
          <div className="" style={{ width: '30%' }}>
            <label className="form-label">Subscription Span</label>
            <span className="text-danger">*</span>
            <CustomDropdown
              name="subscription_span"
              height="44px"
              className="question_dropdown"
              onChange={(value) => handleChange('subscription_span', value)}
              value={productData?.subscription_span}
              options={SubscriptionSpanOptions}
              isMulti={true}
              // containerWidth={'350px'}
            />
          </div>
          {productData.subscription_span.map((el, index) => {
            return (
              <div
                className="d-flex flex-column mx-2"
                key={index}
                style={{ width: '30%' }}
              >
                <label className="fs-16 form-label">
                  {el.label} Wording
                  <span className="text-danger">*</span>
                </label>
                <input
                  className="form-input p-2"
                  name="subscription_span"
                  onChange={(e) =>
                    handleChange(
                      'subscription_span',
                      {
                        ...el,
                        wording: e.target.value,
                      },
                      null,
                      index,
                    )
                  }
                  value={el?.wording}
                />
              </div>
            );
          })}
        </div>
      </div>

      {/* Varinat Section */}
      <div className="row mt-3">
        <h4 className="form-label mb-0">Variant Details</h4>
        <table className="strengths_table mt-3 ms-2" style={{ width: '80%' }}>
          <thead>
            <th>Variant Info</th>
            <th>Trade Price</th>
            <th>RRP Price</th>
            <th>Margin</th>
            {productData?.subscription_span?.map((el, index) => {
              return (
                <th key={index}>
                  {el?.label} <br /> Subsc. Price
                </th>
              );
            })}
          </thead>
          <tbody>
            {productData.strengths.map((el, ind) => {
              return (
                <tr key={ind}>
                  <td
                    style={{
                      width: '100%',
                      minWidth: '80px',
                    }}
                    className="text-center"
                  >
                    {el?.attributes
                      ? Object.keys(el?.attributes).map((elem, index) => {
                          return (
                            <div key={index}>
                              <strong>{elem}</strong> -{' '}
                              {`${el.attributes[elem]?.label}`}
                            </div>
                          );
                        })
                      : '-'}
                  </td>
                  <td
                    style={{
                      width: '100%',
                      minWidth: '80px',
                    }}
                    className="text-center"
                  >
                    {el?.trade_price}
                  </td>
                  <td
                    style={{
                      width: '100%',
                      minWidth: '80px',
                    }}
                    className="text-center"
                  >
                    {el?.rrp_price}
                  </td>
                  <td
                    style={{
                      width: '100%',
                      minWidth: '80px',
                    }}
                    className="text-center"
                  >
                    {el?.margin}
                  </td>
                  {productData?.subscription_span?.map((elem, index) => {
                    let subscriptionObj = el?.subscription_price?.find(
                      (eleme) => eleme.span === elem.value,
                    );
                    return (
                      <td
                        key={index}
                        style={{
                          width: '100%',
                          minWidth: '80px',
                        }}
                      >
                        <input
                          value={subscriptionObj?.price}
                          placeholder="Enter Subscr. Price"
                          type={'number'}
                          className="text-center bg-white"
                          style={{
                            width: '100%',
                            minWidth: '80px',
                          }}
                          // disabled={subscriptionObj?.isDisabled ? true : false}
                          onChange={(e) => {
                            handleChange(
                              'strengths',
                              e.target.value,
                              elem.value,
                              ind,
                            );
                          }}
                        />
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* Follow Up Questions */}
      <SpecificQuestions
        questionsFor={'productForm'}
        handleAddSubQuestion={handleAddSubQuestion}
        handleAddMainQuestion={handleAddMainQuestion}
        handleChange={handleChangeForMainQuestion}
        questionsData={productData.follow_up_questions}
        handleDeleteMainQuestion={handleDeleteMainQuestion}
        handleDeleteSubQuestion={handleDeleteSubQuestion}
        handleChangeForSubQuestion={handleChangeForSubQuestion}
        onDragEndQuestion={onDragEndQuestion}
        handleSelectQuestionModalToggle={handleSelectQuestionModalToggle}
        setSelectedQuestionsIndex={setSelectedQuestionsIndex}
        toggleShowOptionsModal={toggleShowOptionsModal}
      />
      {/* Email */}
      <div className="row mt-3">
        <div className="col-lg-12 col-xl-8 form-group">
          <label className="form-label">Email Wordings</label>
          <span className="text-danger">*</span>
          <TextEditor
            name="subscription_email_template"
            value={productData?.subscription_email_template}
            className={'position-relative'}
            onEditorChange={(value) => {
              handleChange('subscription_email_template', value);
            }}
            editorHeight="30vh"
          />
        </div>
      </div>
      <div className="footer_main" style={{ justifyContent: 'space-between' }}>
        <div>
          <button
            onClick={() => handlePrevious()}
            type="button"
            className="btn btn-sky my-2 me-2"
          >
            Previous
          </button>
        </div>
        <button
          disabled={isSubmitDisabled}
          onClick={() => handleSubmit()}
          type="button"
          // className="btn btn-success my-2 me-2"
          className={`btn ${
            productData.has_landingpage ? 'btn-primary' : 'btn-success'
          } my-2 me-2`}
        >
          {isSubmitDisabled && (
            <span className="submit-loader">
              <Spinner animation="border" size="sm" />
            </span>
          )}{' '}
          {productData.has_landingpage ? 'Next' : 'Submit'}
        </button>
      </div>
    </>
  );
};

export default SubscriptionDetails;
