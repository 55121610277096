import * as network from './network';

// Keyword Apis
const getAllSubscriptions = (params) => {
  return network.get(`subscription`, params, true);
};

const updateSubscription = (id, payload) => {
  return network.put(`subscription/${id}`, payload, true);
};

const cancelSubscription = (id, payload) => {
  return network.put(`subscription/cancelSubscription/${id}`, payload, true);
};

const addSubscriptionNote = (id, payload) => {
  return network.post(`subscription/note/${id}`, payload, true);
};

const updateSubscriptionNote = (id, payload) => {
  return network.put(`subscription/note/${id}`, payload, true);
};

const deleteSubscriptionNote = (id, note_id) => {
  return network.delet(`subscription/note/${id}?note_id=${note_id}`, {}, true);
};

const getFollowUpQuestions = (params, token) => {
  return network.get(
    `product/follow-up/${params.productSlug}/${params.subscriptionId}`,
    {},
    true,
    token,
  );
};

const sendFollowupEmail = (id) => {
  return network.get(`subscription/sendFollowUpEmail/${id}`, {}, true);
};

const createOrder = (id) => {
  return network.get(`subscription/createOrder/${id}`, {}, true);
};

const pauseSubsctiption = (id, payload) => {
  return network.put(`subscription/pauseSubscription/${id}`, payload, true);
};

const unPauseSubsctiption = (id, payload) => {
  return network.put(`subscription/unpauseSubscription/${id}`, payload, true);
};


const restartSubsctiption = (id, payload) => {
  return network.put(`subscription/reStartSubscription/${id}`, payload, true);
};

export default {
  getAllSubscriptions,
  cancelSubscription,
  addSubscriptionNote,
  updateSubscriptionNote,
  deleteSubscriptionNote,
  updateSubscription,
  getFollowUpQuestions,
  sendFollowupEmail,
  createOrder,
  pauseSubsctiption,
  unPauseSubsctiption,
  restartSubsctiption
};
