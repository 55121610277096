import { Form, Formik } from 'formik';
import React from 'react';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import CustomInput from '../../../common/FormComponents/Input';
import { getErrorMessage } from '../../../utils/helpers';
import api from '../../../api/api';
import { useDispatch } from 'react-redux';
import { getOrderById } from '../../../store/reducers/order';
import { COUNTRY_OPTIONS } from '../../../common/constants';
import CustomReactSelect from '../../../common/FormComponents/ReactSelect';

const UpdateAddressModal = (props) => {
  const { orderData, updateForUser, address, updateUserAddress } = props;
  const dispatch = useDispatch();
  let countryObjUser = {};
  if (address?.country_code) {
    countryObjUser = {
      label: `${address?.country}`,
      value: `${address?.country}`,
      country_code: `${address?.country_code || ''}`,
    };
  }
  let countryObjCountry = {};
  if (orderData?.address?.country_code) {
    countryObjCountry = {
      label: `${orderData?.address?.country}`,
      value: `${orderData?.address?.country}`,
      country_code: `${orderData?.address?.country_code || ''}`,
    };
  }
  const initialAddressValues = updateForUser
    ? {
      address_line1: address?.address_line1 || '',
      address_line2: address?.address_line2 || '',
      city: address?.city || '',
      country: countryObjUser || '',
      zip_code: address?.zip_code || '',
      type: address?.type || '',
      _id: address?._id,
    }
    : {
      address_line1: orderData?.address?.address_line1 || '',
      address_line2: orderData?.address?.address_line2 || '',
      city: orderData?.address?.city || '',
      country: countryObjCountry || '',
      zip_code: orderData?.address?.zip_code || '',
      type: orderData?.address?.type || '',
    };

  const addressValidationSchema = Yup.object({
    address_line1: Yup.string().required(
      getErrorMessage('required', 'Street Name'),
    ),
    address_line2: Yup.string(),
    city: Yup.string().required(getErrorMessage('required', 'City')),
    country: Yup.object()
      .nullable()
      .required(getErrorMessage('required', 'Country')),
    zip_code: Yup.string().required(getErrorMessage('required', 'PostCode')),
  });

  const onSubmit = (values, onSubmitProps) => {
    let payload = {
      address: {
        address_line1: values?.address_line1,
        address_line2: values?.address_line2,
        city: values?.city,
        country: values?.country?.value,
        country_code: values?.country?.country_code,
        zip_code: values?.zip_code,
        type: values?.type,
      },
    };
    api
      .updateDeliveryAddress(orderData?._id, payload)
      .then((res) => {
        console.log('res', res);
        props.handleToggle();
        onSubmitProps.setSubmitting(false);
        dispatch(getOrderById(orderData?._id));
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  return (
    <Modal
      show={props.show}
      onHide={props.handleToggle}
      centered={true}
      size="lg"
    >
      <Modal.Header>
        <Modal.Title>
          <h5>Edit Delivery Address</h5>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={initialAddressValues}
          validationSchema={addressValidationSchema}
          onSubmit={updateForUser ? updateUserAddress : onSubmit}
        >
          {(formik) => {
            return (
              <Form>
                <div className={`row`}>
                  <div className={`col-md-12`}>
                    <CustomInput
                      id={'address_line1'}
                      label={'Address line 1'}
                      labelClassname={'form-label'}
                      className={'form-control form-input'}
                      type={'text'}
                      name={'address_line1'}
                      isRequired={true}
                      placeholder={'Enter Address line 1'}
                    />
                  </div>
                  <div className={`col-md-12 mt-3`}>
                    <CustomInput
                      id={'address_line2'}
                      label={'Address line 2'}
                      labelClassname={'form-label'}
                      className={'form-control form-input'}
                      type={'text'}
                      name={'address_line2'}
                      placeholder={'Enter Address line 2 if required'}
                    />
                  </div>
                  <div className={`col-md-4 mt-3`}>
                    <CustomInput
                      id={'city'}
                      label={'City'}
                      labelClassname={'form-label'}
                      className={'form-control form-input'}
                      type={'text'}
                      name={'city'}
                      isRequired={true}
                      placeholder={'City'}
                    />
                  </div>
                  <div className={`col-md-4 mt-3`}>
                    <CustomReactSelect
                      id={'country'}
                      label={'Country'}
                      labelClassname={'form-label'}
                      className={'form-control form-input'}
                      type={'text'}
                      name={'country'}
                      placeholder={'Country'}
                      isRequired={true}
                      placeholderColor="#9aa5ad"
                      border={'1px solid #9aa5ad'}
                      placeholderMarginTop={'-5px'}
                      validationType="required"
                      errorFieldName="Gender"
                      options={COUNTRY_OPTIONS}
                      height={'3rem'}
                      controlBorderRadius={'10px'}
                    />
                  </div>
                  <div className={`col-md-4 mt-3`}>
                    <CustomInput
                      id={'zip_code'}
                      label={'zip_code'}
                      labelClassname={'form-label'}
                      className={'form-control form-input'}
                      type={'text'}
                      name={'zip_code'}
                      placeholder={'Postcode'}
                      isRequired={true}
                    />
                  </div>
                  <hr className="mt-3" />
                  <div className="col-12 d-sm-flex">
                    <button
                      className={`btn btn-primary border-radius-24 fs-16 shadow-none shadow-focus-none my-1`}
                      type="submit"
                      disabled={formik.isSubmitting}
                    >
                      Update Address
                    </button>
                    <button
                      className={`btn btn-danger border-radius-24 fs-16 shadow-none shadow-focus-none my-1 mx-2`}
                      type="button"
                      onClick={props.handleToggle}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateAddressModal;
